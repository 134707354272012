import { useStaticQuery, graphql } from "gatsby"

const ProjectsData = () => {

  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "cm-mockup-1.png" }) {
        ...fluidImage
      }
      imageTwo: file(relativePath: { eq: "tada-mockup-1.png" }) {
        ...fluidImage
      }
      imageThree: file(relativePath: { eq: "ub-mockup-1.png" }) {
        ...fluidImage
      }
      imageFour: file(relativePath: { eq: "bakoba-mockup-1.png" }) {
        ...fluidImage
      }
      imageFive: file(relativePath: { eq: "bb-mockup-1.png" }) {
        ...fluidImage
      }
    }
  `)

  const projects = [
    {
      id: 1,
      image: data.imageOne.childImageSharp.fluid,
      title: 'CareerMentor',
      category: 'Web application & website',
      excerpt: 'Startup website, identity and complete web application platform with matching algorithm',
      description: 'Website and complete web application platform for mentorship startup. Helping mentors and mentees to connect and share career advice and knowledge.',
      color: 'cmorange',
      url: '/project/careermentor',
      client: 'CareerMentor',
      agency: 'None',
      year: '2020',
      role: 'Fullstack, UX & design',
      technologies: 'React.js, Firebase, PWA, GSAP, Google Cloud API, WordPress, Foundation',
      website: 'https://careermentor.dk',
    },
    {
      id: 2,
      image: data.imageTwo.childImageSharp.fluid,
      title: 'Tada',
      category: 'Agency website',
      excerpt: 'Playful and bright website to embrace new agency identity and culture',
      description: 'Website showcasing work and capabilities of creative agency Tada. Playful interactions, interesting case studies and confetti overload.',
      color: 'tadapurple',
      url: '/project/tada',
      client: 'Tada',
      agency: 'Tada',
      year: '2019',
      role: 'Frontend developer',
      technologies: 'WordPress, GSAP, Foundation, Matter.js',
      website: 'https://tada.no',
    },
    {
      id: 3,
      image: data.imageThree.childImageSharp.fluid,
      title: 'Urbanhus',
      category: 'Website',
      excerpt: 'Modern and elegant site for Norwegian architecture and housing developer',
      description: 'Modern and elegant site for Norwegian architecture and housing developer. Night and daylight mode, house finder and dynamic shape drawings.',
      color: 'uhgold',
      url: '/project/urbanhus',
      client: 'Urbanhus',
      agency: 'Tada',
      year: '2019',
      role: 'Frontend developer',
      technologies: 'WordPress, REST API, SVG, Google Cloud API',
      website: 'https://urbanhus.no',
    },
    {
      id: 4,
      image: data.imageFour.childImageSharp.fluid,
      title: 'BAKOBA',
      category: 'Startup website',
      excerpt: 'Website and eCommerce for Danish toy startup with playful animations and custom build guide finder',
      description: 'Website and eCommerce for Danish toy startup. Build with inspiration in their clever building blocks. Playful animations, build guide module and creative children.',
      color: 'bakobared',
      url: '/project/bakoba',
      client: 'BAKOBA',
      agency: false,
      year: '2019',
      role: 'Frontend developer',
      technologies: 'WordPress, WooCommerce, eCommerce, WP REST API, Pakkelabels integration, ePay integration, eConomics integration',
      website: 'https://bakoba.com',
    },
    {
      id: 5,
      image: data.imageFive.childImageSharp.fluid,
      title: 'Bankbudgettet',
      category: 'Web application',
      excerpt: 'User friendly budgetting web application increasing consumer bank loan opportunities',
      description: 'Web application serving as a easy to use budgetting and bank loan tool. Create presentational budgets and get a overview of your finances at the same time.',
      color: 'bbgreen',
      url: '/project/bankbudgettet',
      client: 'BankBudgettet',
      agency: false,
      year: '2019',
      role: 'Fullstack, UX & design',
      technologies: 'React.js, PHP, REST API, MPDF, Chart.js',
      website: '#',
    }
  ]

  return projects;

}

export default ProjectsData;