
import React, { Component } from 'react';
import TransitionLink from "gatsby-plugin-transition-link"
import noScroll from 'no-scroll';

const fadeProjectIn = ({ node }) => {
  if (typeof window !== 'undefined') {
    // Hide the hero of the next page
    node.querySelector('.Hero--project__content').style.opacity = 0;
    node.querySelector('.Hero--project__content').style.transition = 'opacity 2s ease';
    // Hide the project content of the page
    node.querySelector('.project-wrapper').style.opacity = 0;
    node.querySelector('.project-wrapper').style.transition = 'opacity 2s ease';
    // If header was not hidden, we need to remove the color transition
    const header = document.getElementById('Header');
    header.classList.remove('Header--animate--linkColor');
    // Delay we need to wait for the entry animation to finish
    const delay = 800;
    setTimeout(() => {
      // When entry animation is done show the hero
      node.querySelector('.Hero--project__content').style.opacity = 1;
      // Hide loading
      document.getElementsByTagName('body')[0].style.cursor = 'unset';
      // Enable scroll
      noScroll.off();
    }, delay + 1);
    setTimeout(() => {
      // When entry animation is done and the hero is shown, show the project content
      node.querySelector('.project-wrapper').style.opacity = 1;
    }, delay + 500);
    setTimeout(() => {
      // Show menu again if it was hidden
      header.classList.remove('Header--hidden');
    }, delay + 500);
    setTimeout(() => {
      // Show nav again
      document.getElementById('Nav__checkbox').classList.remove('Nav__trigger--hide');
    }, delay + 900);
  }
}

class ProjectLink extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    // Show loading
    this.myRef.current.classList.add('loading');
    document.getElementsByTagName('body')[0].style.cursor = 'wait';
  }
  
  render() {    

    const { ...props } = this.props
    
    const lengthOut = 1.5;
    const lengthIn = 0.5;
    const delay = 1;

    return (
      <TransitionLink
        {...props}
        ref={this.myRef}
        onClick={this.handleClick}
        entry={{
          length: lengthIn,
          appearAfter: delay,
          trigger: ({ node }) =>
            fadeProjectIn({ node }),
        }}
        exit={{
          length: lengthOut,
        }}
        trigger={async pages => {
          // Wait until we access to the next page before starting
          const entry = await pages.entry

          // Get scroll position
          let scroll = 0; 
          if (typeof window !== 'undefined') {
            scroll = window.pageYOffset || document.documentElement.scrollTop;

            // Hide menu btn
            document.getElementById('NavTrigger').classList.remove('Nav__trigger--visible');
            document.getElementById('Nav__checkbox').classList.add('Nav__trigger--hide');

            // Hide header if we're scrolled
            if(scroll > 0) {
              document.getElementById('Header').classList.add('Header--hidden');
            } else {
              // Just animate link color
              document.getElementById('Header').classList.add('Header--animate--linkColor');
            }

            // Get the height of the hero on next page (we need it in order to animate the box)
            const entryProjectHeroHeight = entry.node.querySelector('.Hero--project').clientHeight;

            // Get the position of the hero image on the next page (we need it in order to animate the current image)
            const entryProjectImageOffsetTop = entry.node.querySelector('.Hero--project__image').getBoundingClientRect().top;
            const entryProjectImageOffsetLeft = entry.node.querySelector('.Hero--project__image').getBoundingClientRect().left;

            // Get the overlay of the chosen project
            const transitionOverlay = props.children[0].ref.current.querySelector('.transition-overlay');

            // Get the position of the chosen overlay
            const transitionOverlayHeight = transitionOverlay.clientHeight;
            const offsetTop = transitionOverlay.getBoundingClientRect().top;
            const offsetLeft = transitionOverlay.getBoundingClientRect().left;

            // Calculate the height difference between the overlay and the hero on next page
            const heightDifference = entryProjectHeroHeight - transitionOverlayHeight;

            // Calculate the new bottom position for the overlay
            const overlayBottom = offsetTop - heightDifference;

            // Get the image of the chosen project and its position for animation
            const projectImage = props.children[0].ref.current.querySelector('.gatsby-image-wrapper');
            const projectImageOffsetLeft = projectImage.getBoundingClientRect().left;
            const projectImageOffsetTop = projectImage.getBoundingClientRect().top;

            // Calculate the new position for the image 
            const imageLeft = entryProjectImageOffsetLeft - projectImageOffsetLeft;
            const imageTop = entryProjectImageOffsetTop - projectImageOffsetTop;
            const imageY = imageTop + scroll;

            // We got all needed coordinates, start animation (transitions set via css)
            
            // Disable scroll
            noScroll.on();

            // Make sure the image is on top of the overlay
            projectImage.style.zIndex = 7;

            // Add active transition class to the overlay
            transitionOverlay.classList.add('transition-overlay--active');
            // Set the new height for the overlay
            transitionOverlay.style.height = entryProjectHeroHeight + 'px';
            // Set the new bottom position of the overlay 
            overlayBottom > 0 ? transitionOverlay.style.transform = `translateY(-${overlayBottom}px)` : transitionOverlay.style.transform = `translateY(${Math.abs(overlayBottom)}px)`;
            // Set the new left position of the overlay
            transitionOverlay.style.left = '-' + offsetLeft + 'px';
            // Set the new width of the overlay
            transitionOverlay.style.width = 100 + 'vw';
            
            // Set the new top position of the image
            projectImage.style.top = imageY + 'px';

            // Set the new left position of the image
            projectImage.style.transform = `translateX(${imageLeft}px)`;
          }

        }}

        {...props}>
        {props.children}
      </TransitionLink>
    )
  }

}

export default ProjectLink