import React, { Component } from 'react';
import Img from "gatsby-image"

import ProjectLink from "../transitions/ProjectLink"
import icons from '../../images/icons.svg';
const PointerIcon = icons + '#pointer';

class CasePreview extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  render() {
    const { image, title, category, description, color, url } = this.props;
    return (
      <article className="CasePreview bg-lightgray fadein">
        <ProjectLink to={url}>
          <figure className={`CasePreview__image bg-${color}`} ref={this.myRef}>
            <Img fluid={image} />
            <div className="transition-overlay"></div>
            <div className="transition-overlay-backdrop"></div>
          </figure>
          <div className="CasePreview__inner">
            <p className="CasePreview__category">
              <span>{category}</span>
            </p>
            <h2 className="CasePreview__title">{title}</h2>
            <p className="CasePreview__description">{description}</p>
            <p className="CasePreview__link Link--arrow">
              <svg className="fill-black">
                <use xlinkHref={PointerIcon}></use>
              </svg>
              <span>See more</span>
            </p>
          </div>
        </ProjectLink>
      </article>
    )
  }
}

export default CasePreview